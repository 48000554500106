<template>
  <form
    novalidate
    @submit.prevent="submit"
  >
    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Applicant                                                                              -->
    <!-- -------------------------------------------------------------------------------------- -->

    <div
      v-if="!isAddCoApplicantLead"
      class="applicant"
    >
      <zg-transition-block @after-transition="validate">
        <div
          v-if="showRefinanceChoice"
          class="row row--full-width"
        >
          <form-input
            field="refinance"
            group="global"
          />
        </div>
      </zg-transition-block>

      <zg-transition-block @after-transition="validate">
        <div
          v-if="!showRefinanceChoice || hasSelectedRefinance"
          class="input-box"
        >
          <p class="refinance">
            {{ content.refinancePreAmount }}
            <strong>{{ loanAmount }}</strong>
            {{ content.refinancePostAmount }}
          </p>

          <div class="row">
            <form-input
              field="totalDebt"
              group="debts"
              @blur="updateRefinanceValues"
            />
            <form-input
              class="s-margin-top"
              field="totalDebtMonthlyCost"
              group="debts"
              @blur="showHighDebtMonthlyCostWarning"
            />
          </div>
        </div>
      </zg-transition-block>

      <notice
        v-if="hasPrefilledFields"
        :on-beige-background="onBeigeBackground"
        name="prefill"
      />

      <zg-transition-block @after-transition="visiblePurposeField">
        <div
          v-if="showPurpose"
          class="row"
        >
          <form-input
            field="loanPurpose"
            group="global"
          />
        </div>
      </zg-transition-block>

      <div class="row">
        <form-input
          field="ssn"
          group="applicant"
        />
        <form-input
          field="phone"
          group="applicant"
        />
      </div>

      <div class="row">
        <div>
          <form-input
            field="email"
            group="applicant"
          />

          <verify
            field="email"
            group="applicant"
          />
        </div>

        <form-input
          field="children"
          group="applicant"
        />
      </div>

      <div class="row">
        <div>
          <form-input
            field="employmentType"
            group="applicant"
          />

          <zg-transition-block @after-transition="visibleEmploymentFields('applicant')">
            <div
              v-if="showEmploymentFields('applicant')"
              class="input-box input-box--with-arrow input-box--column"
            >
              <form-input
                field="monthlyIncome"
                group="applicant"
              />

              <form-input
                v-if="hasEmployment('applicant')"
                field="employer"
                group="applicant"
              />

              <form-input
                field="employedSince"
                group="applicant"
              />

              <zg-transition-fade>
                <form-input
                  v-if="timeBasedEmployment('applicant')"
                  field="employedTo"
                  group="applicant"
                />
              </zg-transition-fade>
            </div>
          </zg-transition-block>
        </div>

        <div>
          <form-input
            field="maritalStatus"
            group="applicant"
          />

          <zg-transition-block @after-transition="visibleResidenceFields('applicant')">
            <div
              v-if="showResidenceFields('applicant')"
              class="input-box input-box--with-arrow input-box--column"
            >
              <form-input
                field="residenceType"
                group="applicant"
                @input="updateResidenceType"
              />
              <form-input
                field="rent"
                group="applicant"
              />
            </div>
          </zg-transition-block>
        </div>
      </div>

      <!-- -------------------------------------------------------------------------------------- -->
      <!-- Add Co Applicant button                                                                -->
      <!-- -------------------------------------------------------------------------------------- -->

      <div v-if="!getApplicationEvents.createApplicationWithCoApplicant">
        <add-co-applicant-button />
      </div>
    </div>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Co Applicant                                                                           -->
    <!-- -------------------------------------------------------------------------------------- -->

    <zg-transition-block @after-transition="validate">
      <div
        v-if="hasCoApplicant || isAddCoApplicantLead"
        class="co-applicant"
      >
        <div class="row row--full-width">
          <form-input
            field="livesWithCoApplicant"
            group="applicant"
            @input="updateMaritalStatus"
          />
        </div>

        <div class="row">
          <form-input
            field="ssn"
            group="coApplicant"
          />
          <form-input
            field="phone"
            group="coApplicant"
          />
        </div>

        <zg-transition-block>
          <div
            v-if="!livesWithCoApplicant"
            class="row"
          >
            <div>
              <form-input
                field="email"
                group="coApplicant"
              />

              <verify
                field="email"
                group="coApplicant"
              />
            </div>

            <form-input
              field="children"
              group="coApplicant"
            />
          </div>
        </zg-transition-block>

        <div class="row">
          <zg-transition-block @after-transition="validate">
            <div v-if="livesWithCoApplicant">
              <form-input
                field="email"
                group="coApplicant"
              />
              <form-input
                field="rent"
                group="coApplicant"
              />
            </div>
          </zg-transition-block>

          <div>
            <form-input
              field="employmentType"
              group="coApplicant"
            />

            <zg-transition-block @after-transition="visibleEmploymentFields('coApplicant')">
              <div
                v-if="showEmploymentFields('coApplicant')"
                class="input-box input-box--with-arrow"
              >
                <form-input
                  field="monthlyIncome"
                  group="coApplicant"
                />

                <form-input
                  v-if="hasEmployment('coApplicant')"
                  field="employer"
                  group="coApplicant"
                />

                <form-input
                  field="employedSince"
                  group="coApplicant"
                />

                <zg-transition-fade>
                  <form-input
                    v-if="timeBasedEmployment('coApplicant')"
                    field="employedTo"
                    group="coApplicant"
                  />
                </zg-transition-fade>
              </div>
            </zg-transition-block>
          </div>

          <zg-transition-block @after-transition="validate">
            <div v-if="!livesWithCoApplicant">
              <form-input
                field="maritalStatus"
                group="coApplicant"
              />

              <zg-transition-block @after-transition="visibleResidenceFields('coApplicant')">
                <div
                  v-if="showResidenceFields('coApplicant')"
                  class="input-box input-box--with-arrow"
                >
                  <form-input
                    field="residenceType"
                    group="coApplicant"
                  />
                  <form-input
                    field="rent"
                    group="coApplicant"
                  />
                </div>
              </zg-transition-block>
            </div>
          </zg-transition-block>
        </div>
      </div>
    </zg-transition-block>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Terms                                                                                  -->
    <!-- -------------------------------------------------------------------------------------- -->

    <div
      v-if="!getApplicationEvents.createApplication && !isAddCoApplicantLead"
      class="row row--full-width"
    >
      <form-input
        field="digitalCommunication"
        group="applicant"
      />
    </div>

    <div
      v-if="isAddCoApplicantLead"
      class="row row--full-width"
    >
      <form-input
        field="digitalCommunication"
        group="coApplicant"
      />
    </div>

    <div
      v-if="!getApplicationEvents.createApplication && !isAddCoApplicantLead"
      class="row row--full-width"
    >
      <form-input
        field="userAgreement"
        group="applicant"
      />
    </div>

    <div
      v-if="isAddCoApplicantLead"
      class="row row--full-width"
    >
      <form-input
        field="userAgreement"
        group="coApplicant"
      />
    </div>
  </form>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import FormInput from '../../../../elements/_shared/misc/FormInput'
  import Notice from '../../../../elements/_shared/misc/Notice'
  import AddCoApplicantButton from '../../../../elements/cl/misc/AddCoApplicantButton'
  import Verify from '../../../../elements/_shared/misc/Verify'

  export default {
    name: 'Step2SE',
    components: {
      Verify,
      Notice,
      AddCoApplicantButton,
      FormInput,
      ZgTransitionBlock: () => import(/* webpackChunkName: 'zc/zg-transition-block' */
        '@zc/components/ZgTransitionBlock/ZgTransitionBlock'),
      ZgTransitionFade: () => import(/* webpackChunkName: 'zc/zg-transition-fade' */
        '@zc/components/ZgTransitionFade/ZgTransitionFade')
    },
    props: {
      onBeigeBackground: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: () => ({
      hasPrefilledFields: false
    }),
    computed: {
      ...mapGetters('content', ['getContent']),
      ...mapGetters('experiments', ['getExperiments']),
      ...mapGetters('translations', ['getLocale']),
      ...mapGetters('form', ['getForm']),
      ...mapGetters('cl/application', ['getApplicationEvents']),
      content () {
        return this.getContent?.application?.singleStep?.personalDetails ?? {}
      },
      showFields () {
        return this.getForm?.global?.showFields?.value
      },
      showPurpose () {
        return this.getForm?.global?.refinance?.value !== null
      },
      showRefinanceChoice () {
        return !this.HIDE_REFINANCE_CHOICE_ON_PURPOSE.includes(this.getForm?.global?.loanPurpose?.value)
      },
      hasCoApplicant () {
        return !!this.getForm?.global?.hasCoApplicant?.value
      },
      livesWithCoApplicant () {
        return !!this.getForm?.applicant?.livesWithCoApplicant?.value
      },
      isAddCoApplicantLead () {
        return this.getForm?.global?.leadType?.value === 'addCoApplicant'
      },
      isContinueApplicationLead () {
        return this.getForm?.global?.leadType?.value === 'continueApplication'
      },
      hasSelectedRefinance () {
        return this.getForm?.global?.refinance?.value
      },
      loanAmount () {
        return this.$options.filters.currency(this.getForm?.global?.loanAmount?.value, this.MARKET, this.getLocale)
      },
      collectLoans () {
        return this.getForm?.global?.loanPurpose?.value === this.LOAN_PURPOSE.COLLECT_LOANS
      }
    },
    async mounted () {
      await this.viewTracking({ label: 'personalData' })
      await this.fetchFormAlternatives()
      await this.addCustomerData()

      this.$emit('loaded')
    },
    methods: {
      ...mapActions('messages', ['setModalMessage']),
      ...mapActions('experiments', ['activateExperiment']),
      ...mapActions('form', ['setField', 'validateFields', 'resetField', 'blurField', 'focusField']),
      ...mapActions('cl/application', ['fetchPrefillData']),
      ...mapActions('cl/analytics', ['viewTracking']),
      ...mapActions('cl/formAlternatives', ['fetchFormAlternatives']),
      submit () {
        this.$emit('submit')
      },
      async visiblePurposeField () {
        await this.validate()
        if (this.showPurpose) await this.viewTracking({ label: 'purpose' })
      },
      async visibleEmploymentFields (applicant) {
        await this.validate()

        if (this.showEmploymentFields(applicant)) {
          const label = applicant === 'coApplicant'
            ? 'co-applicant-employment'
            : 'personal-data-employment'
          await this.viewTracking({ label })
        }
      },
      showEmploymentFields (applicant) {
        return (
          this.getForm?.[applicant]?.employmentType?.value ||
          this.getForm?.[applicant]?.monthlyIncome?.value ||
          this.getForm?.[applicant]?.employer?.value ||
          this.getForm?.[applicant]?.employedSince?.value
        )
      },
      async visibleResidenceFields (applicant) {
        await this.validate()

        if (this.showResidenceFields(applicant)) {
          const label = applicant === 'coApplicant'
            ? 'co-applicant-housing'
            : 'personal-data-housing'
          await this.viewTracking({ label })
        }
      },
      showResidenceFields (applicant) {
        return (
          this.getForm?.[applicant]?.maritalStatus?.value ||
          this.getForm?.[applicant]?.residenceType?.value ||
          this.getForm?.[applicant]?.rent?.value
        )
      },
      employmentType (applicant) {
        const employmentType = this.getForm?.[applicant]?.employmentType?.value || ''
        return parseInt(employmentType, 10)
      },
      hasEmployment (applicant) {
        if (!this.employmentType(applicant)) return false

        return this.HAS_EMPLOYMENT?.includes(this.employmentType(applicant))
      },
      timeBasedEmployment (applicant) {
        if (!this.employmentType(applicant)) return false

        return (
          this.getForm?.[applicant]?.employmentType?.valid &&
          this.TIME_BASED_EMPLOYMENT?.includes(this.employmentType(applicant))
        )
      },
      async updateResidenceType () {
        if (!this.livesWithCoApplicant) return

        await this.setField({
          group: 'coApplicant',
          field: 'residenceType',
          value: this.getForm?.applicant?.residenceType?.value
        })

        await this.blurField({ group: 'coApplicant', field: 'residenceType' })
      },
      async updateMaritalStatus () {
        if (!this.livesWithCoApplicant) return
        const maritalStatus = this.getForm?.applicant?.maritalStatus?.value

        if (this.SINGLE_MARITAL_STATUSES.indexOf(maritalStatus) >= 0) {
          await this.resetField({
            group: 'applicant',
            field: 'maritalStatus'
          })
        }

        await this.setField({
          group: 'coApplicant',
          field: 'residenceType',
          value: this.getForm?.applicant?.residenceType?.value
        })

        await this.blurField({ group: 'coApplicant', field: 'residenceType' })
      },
      async updateRefinanceValues () {
        const amount = this.getForm?.global?.loanAmount?.value || 0
        const totalDebt = this.getForm?.debts?.totalDebt?.value

        await this.setField({
          group: 'global',
          field: 'refinanceNewLoanAmount',
          value: (amount - totalDebt),
          touched: true,
          valid: true
        })

        await this.setField({
          group: 'global',
          field: 'refinanceAmount',
          value: totalDebt || 0,
          touched: true,
          valid: true
        })

        if (totalDebt === amount) {
          await this.setToCollectLoans()
        } else if (this.collectLoans === true) {
          await this.resetField({
            group: 'global',
            field: 'loanPurpose'
          })
        }
      },
      async validate () {
        await this.validateFields()
      },
      async addCustomerData () {
        if (this.getForm?.global?.leadType?.value) return

        if (this.getApplicationEvents.fetchedCustomerData) {
          this.hasPrefilledFields = true
        } else {
          this.hasPrefilledFields = await this.fetchPrefillData()
        }
      },
      async setToCollectLoans () {
        await this.setField({
          group: 'global',
          field: 'loanPurpose',
          value: this.LOAN_PURPOSE.COLLECT_LOANS,
          touched: true,
          valid: true
        })

        await this.blurField({ group: 'global', field: 'loanPurpose' })
      },
      showHighDebtMonthlyCostWarning () {
        if (this.getForm.debts.totalDebtMonthlyCost.value > 12000) {
          this.setModalMessage({
            group: 'form',
            name: 'highDebtMonthlyCostWarning',
            show: true,
            callbacks: {
              no: async () => {
                await this.resetField({
                  group: 'debts',
                  field: 'totalDebtMonthlyCost'
                })
              }
            }
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .refinance {
    @include type-body-m;
  }

  .s-margin-top {
    margin: rhythm(small) 0 0;

    @include mq(medium) {
      margin: initial;
    }
  }
</style>
